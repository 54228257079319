import { React } from 'react';
import './Home.css';
import Slideshow from '../Slideshow/Slideshow';
import Items from '../Items/Items';
import { useSelector } from 'react-redux';

function Home() {
  const items = useSelector(state => state.items);
  const slides = useSelector(state => state.slideshow);

  return (
    <div>
      <div className='header'>
        <Slideshow slidesState={slides} />
      </div>
      <div className="main">
        <Items itemsState={items} />
      </div>
    </div>
  )
}

export default Home