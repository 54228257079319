import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchOrder = createAsyncThunk("order/fetchOrder", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/order/${parameters.orderId}?token=${parameters.token}`);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const orderSlice = createSlice({
    name: "order",
    initialState: {
        order: {},
        status: null,
        error: null,
    },
    reducers: {
        setStatus: (state, { payload: status }) => {
            state.status = status;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrder.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchOrder.fulfilled, (state, action) => {
                state.status = "success";
                state.order = action.payload;
            })
            .addCase(fetchOrder.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            });
    },
});

export const { setStatus } = orderSlice.actions;
export default orderSlice.reducer;