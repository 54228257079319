import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { fetchItem, setItem } from './catalog.slice';
import { useDispatch, useSelector } from 'react-redux';
import './Catalog.css';
import cart from '../../images/cart.png';
import ImageViewer from '../ImageViewer/ImageViewer';
import Modal from '../Modal/Modal';
import ModalCartItem from '../ModalCartItem/ModalCartItem';
import { addItem, calculatePrice, splitValue } from '../Cart/cart.slice';
import { handleInputChange } from '../ModalCartItem/modalCartItem.slice';
import Items from '../Items/Items';

function Catalog({ itemState }) {
    const location = useLocation();
    const currentURL = location.pathname;
    const itemId = Number(currentURL.split('/').pop());

    const dispatch = useDispatch();
    useEffect(() => {
        if (itemState) {
            return;
        }
        dispatch(fetchItem(itemId));
    }, [dispatch, itemId, itemState]);
    useEffect(() => {
        document.querySelector('main').scrollTo(0, 0);
    }, [location.pathname]);

    let { item, status } = useSelector(state => state.catalog);
    item = itemState === undefined ? item : itemState;
    status = itemState === undefined ? status : 'success';

    const [modalClasses, setModalClasses] = useState('modal-base-container modal-hide');
    const modalContainerFocus = (modalContainer) => {
        modalContainer.focus();
    };
    const showModal = (item) => {
        setModalClasses('modal-base-container');
        dispatch(setItem(item));
    };

    const hideModal = () => {
        setModalClasses('modal-base-container modal-hide');
    };

    const addToCart = (evt) => {
        dispatch(addItem(item));
        hideModal();
    };

    return (
        <div>
            {
                status === 'loading' &&
                <div className='catalog-item-container'>
                    <div className="catalog-image-container">
                        <div className="catalog-image loading catalog-loading"></div>
                    </div>
                    <div className="catalog-info">
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                        <p className='loading catalog-loading'></p>
                    </div>
                    <div className="relations-container">
                        <p className='loading catalog-loading catalog-item-relations'></p>
                        <div className="relations">
                            {
                                <Items itemsState={{ items: [], status: 'loading' }} shortNavLink={true} />
                            }
                        </div>
                    </div>
                </div>
            }
            {
                status === 'success' &&
                <div className='catalog-item-container'>
                    <div className="catalog-image-container">
                        <div className="catalog-image">
                            <ImageViewer images={item.images} dots={true} controllers={true} autoPlay={true} />
                        </div>
                    </div>
                    <div className="catalog-info">
                        <div className="catalog-price">
                            <p className='catalog-item-price'>{splitValue(item.price.price)} {item.price.currency} {item?.units?.find(unit => unit.id === item.price?.unit).unit ? '• за 1' : ''} {item?.units?.find(unit => unit.id === item.price.unit).unit}</p>
                            {
                                itemState === undefined &&
                                <button className='cart-add-button' onClick={() => showModal(item)}>
                                    <img src={cart} alt="" />
                                    В корзину
                                </button>
                            }
                        </div>
                        <p className='catalog-item-title'>{item.title}</p>
                        <NavLink to={`/shop/${item.storeId}`} className='clip-item-store catalog-item-store-link'><p className='catalog-item-store'>{item.store}</p></NavLink>
                        <p className="catalog-description description">{item.description}</p>
                        <div className="catalog-item-info-container">
                            <p className='catalog-item-relations'>О товаре</p>
                            <p className='catalog-item-info'><span className='catalog-item-propertyName'>Код товара: </span>{item.id}</p>
                            <p className='catalog-item-info'><span className='catalog-item-propertyName'>Производство: </span>{item.brand}</p>
                            <p className='catalog-item-info'><span className='catalog-item-propertyName'>Категория: </span>{item.category}</p>
                            <p className='catalog-item-info'><span className='catalog-item-propertyName'>Тип: </span>{item.type}</p>
                        </div>
                        {
                            item.discounts?.length > 0 &&
                            <div className="catalog-item-discounts-container">
                                <p className='catalog-item-relations'>Скидки</p>
                                {
                                    item.discounts?.map((discount, index) => {
                                        return <p key={index} className='catalog-item-info'><span className='catalog-item-propertyName'>От {discount.count} {item.units?.find(unit => unit.id === item.price.unit).unit}: </span>{discount.price} {item.price.currency} • за 1 {item.units?.find(unit => unit.id === item.price.unit).unit}</p>
                                    })
                                }

                            </div>
                        }
                        {
                            item.units?.length > 0 &&
                            <div className="catalog-item-prices-container">
                                <p className='catalog-item-relations'>Цены</p>
                                {
                                    item.units?.map((unit) => {
                                        return <p key={unit.id} className='catalog-item-info'><span className='catalog-item-propertyName'>1 {unit.unit}: </span>{calculatePrice(item, unit, true, true)}</p>
                                    })
                                }
                            </div>
                        }
                        <div className="catalog-item-prices-container">
                            <p className='catalog-item-relations'>Вес товара</p>
                            {
                                item.units?.filter(unit => unit.unit !== 'кг').map((unit) => {
                                    return <p key={unit.id} className='catalog-item-info'><span className='catalog-item-propertyName'>1 {unit.unit}: </span>{splitValue(item.units.find(fUnit => fUnit.unit === 'кг')?.count / unit.count) + ' кг'}</p>
                                })
                            }
                        </div>
                    </div>
                    {
                        (itemState === undefined && item.relations?.length) > 0 &&
                        <div className="relations-container">
                            <p className='catalog-item-relations'>Связанные товары</p>
                            <div className="relations">
                                {
                                    <Items itemsState={{ items: item.relations, status: 'success' }} shortNavLink={true} />
                                }
                            </div>
                        </div>
                    }
                </div>
            }
            {
                status === 'error' &&
                <div className="error">Произошла ошибка при загрузки товара, попробуйте перегрузить страницу</div>
            }
            <Modal modalContainerFocus={modalContainerFocus} modalClasses={modalClasses} hideModal={hideModal} style={{ width: '70%', height: 'auto', maxWidth: '900px' }} accept={addToCart} onKeyDown={(evt) => dispatch(setItem(handleInputChange(evt, item.units.find(unit => unit.id === item.price.unit), item)))}>
                <ModalCartItem addToCart={addToCart} itemState={item} setItem={setItem} submitText={'В корзину'} />
            </Modal>
        </div>
    )
}

export default Catalog