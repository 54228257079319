import React, { useEffect, useRef, useState } from 'react';
import './Clips.css';
import ImageViewer from '../ImageViewer/ImageViewer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItems, setAutoPlay, setItem } from './clips.slice';
import up from '../../images/up.png';
import down from '../../images/down.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { addItem, splitValue } from '../Cart/cart.slice';
import cart from '../../images/cart.png';
import Modal from '../Modal/Modal';
import ModalCartItem from '../ModalCartItem/ModalCartItem';
import { handleInputChange } from '../ModalCartItem/modalCartItem.slice';

function Clips() {
    const { items, status } = useSelector(state => state.clips);
    const dispatch = useDispatch();
    const [clipIndex, setClipIndex] = useState(0);
    const clipsContainerRef = useRef(null);

    const [modalClasses, setModalClasses] = useState('modal-base-container modal-hide');
    const itemState = useSelector(state => state.clips.itemState);
    const modalContainerFocus = (modalContainer) => {
        modalContainer.focus();
    };
    const showModal = (item) => {
        setModalClasses('modal-base-container');
        dispatch(setItem(item));
    };

    const hideModal = () => {
        setModalClasses('modal-base-container modal-hide');
    };

    const addToCart = (evt) => {
        dispatch(addItem(itemState));
        hideModal();
    };

    const location = useLocation();
    useEffect(() => {
        if (location.hash !== '#modal') {
            hideModal();
        }
    }, [location.hash]);

    const navigate = useNavigate();

    const [clip] = useState(location.pathname);
    useEffect(() => {
        dispatch(fetchItems(clip));
    }, [dispatch, clip]);

    useEffect(() => {
        if (location.pathname === '/clips' && items?.length > 0) {
            navigate('/clips/' + items[0].id);
        }
    }, [items, location.pathname, navigate]);

    const handleScroll = (side) => {
        if (clipsContainerRef.current && clipsContainerRef.current.querySelector('.clip-container') === null) {
            return;
        }
        const width = clipsContainerRef.current.querySelector('.clip-container').getBoundingClientRect().height + 10;
        if (side === 'up') {
            if (clipIndex === 0) return;
            clipsContainerRef.current.animate(
                [
                    { transform: 'translateY(' + (-1 * (clipIndex - 1) * width) + 'px)' },
                ],
                {
                    duration: 300,
                    easing: 'ease',
                    fill: 'forwards',
                }
            );
            setClipIndex(clipIndex - 1);
            dispatch(setAutoPlay(clipIndex - 1));
            navigate('/clips/' + items[clipIndex - 1].id);
            return 'up';
        } else {
            if (clipIndex === items.length - 1) return;

            clipsContainerRef.current.animate(
                [
                    { transform: 'translateY(' + (-1 * (clipIndex + 1) * width) + 'px)' },
                ],
                {
                    duration: 300,
                    easing: 'ease',
                    fill: 'forwards',
                }
            );
            setClipIndex(clipIndex + 1);
            dispatch(setAutoPlay(clipIndex + 1));
            navigate('/clips/' + items[clipIndex + 1].id);
            return 'down';
        }
    }

    let startY, endY;
    const handleTouchStart = (e) => {
        startY = e.touches[0].clientY;
    }

    const handleTouchMove = (e) => {
        const width = clipsContainerRef.current.querySelector('.clip-container').getBoundingClientRect().height + 10;

        endY = e.touches[0].clientY;
        if (clipsContainerRef.current) {
            clipsContainerRef.current.animate(
                [
                    { transform: 'translateY(' + ((-1 * clipIndex * width) + (endY - startY)) + 'px)' },
                ],
                {
                    duration: 150,
                    fill: 'forwards',
                }
            );
        }
    }

    const handleTouchEnd = (e) => {
        const width = clipsContainerRef.current.querySelector('.clip-container').getBoundingClientRect().height + 10;
        let scrollResult;
        endY = e.changedTouches[0].clientY;
        if (startY - endY < -50) {
            scrollResult = handleScroll('up');
            if (scrollResult) {
                return;
            }
        }

        if (startY - endY > 50) {
            scrollResult = handleScroll('down');
            if (scrollResult) {
                return;
            }
        }
        clipsContainerRef.current.animate(
            [
                { transform: 'translateY(' + ((-1 * clipIndex * width)) + 'px)' },
            ],
            {
                duration: 100,
                easing: 'ease',
                fill: 'forwards',
            }
        );
    }

    useEffect(() => {
        const handleTouchMoveWindow = (e) => {
            e.preventDefault();
        };

        const options = { passive: false };
        window.addEventListener('touchmove', handleTouchMoveWindow, options);

        return () => {
            window.removeEventListener('touchmove', handleTouchMoveWindow);
        };
    });

    return (
        <div className='clips-box'>
            {
                status === 'loading' &&
                <div className='clips-container'>
                    <div className="clips-controls">
                        <div className="clip-up-down">
                            <button className='button clip-control-button'><img src={up} alt="" /></button>
                            <button className='button clip-control-button'><img src={down} alt="" /></button>
                        </div>
                    </div>
                    <div ref={clipsContainerRef} className="clips">
                        <div className="clip-container loading"></div>
                    </div>
                </div>
            }
            {
                status === 'error' &&
                <div className='error'>Не удалось загрузить страницу, повторите попытку</div>
            }
            {
                status === 'success' &&
                <div className='clips-container'>
                    <div className="clips-controls">
                        <div className="clip-up-down">
                            <button className='button clip-control-button' onClick={() => handleScroll('up')}><img src={up} alt="" /></button>
                            <button className='button clip-control-button' onClick={() => handleScroll('down')}><img src={down} alt="" /></button>
                        </div>
                    </div>
                    <div ref={clipsContainerRef} className="clips" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                        {
                            items.map(item =>
                                <div className="clip-container" key={item.id}>
                                    <ImageViewer key={item?.id} images={item?.images?.filter(image => image.type === 'video')} dots={true} controllers={true} autoPlay={item?.autoPlay} />
                                    <div className="clip-info-container">
                                        <div>
                                            <NavLink to={window.location.origin + '/catalog/' + item?.id} className='link'><p className="clip-item-title">{item?.title}</p></NavLink>
                                            <NavLink className='clip-item-store' to={window.location.origin + '/shop/' + item?.storeId}>{item?.store}</NavLink>
                                        </div>
                                        <div className="clip-item-price-container">
                                            <div className="item-price-container">
                                                <span className="item-price">{splitValue(item.price?.price)}
                                                    <span className='price-currency'> {item.price?.currency ?? '₽'}</span>
                                                </span>
                                                <span className="unit clip-price-unit"> {item?.units?.find(unit => unit.id === item.price?.unit).unit ? '• за 1' : ''} {item?.units?.find(unit => unit.id === item.price.unit).unit}</span>
                                            </div>
                                            <button className='cart-add-button' onClick={() => showModal(item)}>
                                                <img src={cart} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

            }

            <Modal modalContainerFocus={modalContainerFocus} modalClasses={modalClasses} hideModal={hideModal} style={{ width: '70%', height: 'auto', maxWidth: '900px' }} accept={addToCart} onKeyDown={(evt) => dispatch(setItem(handleInputChange(evt, itemState.units.find(unit => unit.id === itemState.price.unit), itemState)))}>
                <ModalCartItem addToCart={addToCart} itemState={itemState} setItem={setItem} submitText={'В корзину'} />
            </Modal>
        </div>
    );
}

export default Clips