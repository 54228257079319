import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchShops } from './shops.slice';
import './Shops.css';
import { NavLink } from 'react-router-dom';
import ImageComponent from '../ImageComponent/ImageComponent';
import { host } from '../../App';
import background from '../../images/background.png';
import shopAvatar from '../../images/shop-avatar.png';

function Shops({ shopsProps, statusProps, longUrl }) {
    let { shops, status } = useSelector((state) => state.shops);
    shops = shopsProps || shops;
    status = statusProps || status;

    const dispatch = useDispatch();
    useEffect(() => {
        if (shopsProps) return;
        dispatch(fetchShops());
    }, [dispatch, shopsProps]);

    const { origin } = window.location;
    return (
        <div>
            {
                status === 'loading' &&
                <div>
                    <h3>Магазины</h3>
                    <div className="shops-container">
                        {
                            Array.from({ length: 10 }).map((_, i) => <p key={i} className='shop-container loading shops-loading'></p>)
                        }
                    </div>
                </div>
            }
            {
                status === 'error' &&
                <div className='error'>Ошибка при загрузки страницы, попробуйте обновить страницу</div>
            }
            {
                status === 'success' &&
                <div>
                    <h3>Магазины</h3>
                    <div className="shops-container">
                        {
                            shops.map((shop, index) => (
                                <NavLink key={index} to={longUrl ? `${origin}/shop/${shop._id}` : `/shop/${shop._id}`} className='shop-container'>
                                    <div className="shop-image-container">
                                        <ImageComponent src={shop?.images?.find(image => image.type === 'background') ? host.url + '/api/' + shop?.images?.find(image => image.type === 'background')?.src : background} alt="" />
                                    </div>
                                    <div className='shop-main-image'>
                                        <ImageComponent src={shop?.images?.find(image => image.type === 'main') ? host.url + '/api/' + shop?.images?.find(image => image.type === 'main')?.src : shopAvatar} alt="" />
                                    </div>
                                    <div className="shop-info-container">
                                        <p className='shop-title'>{shop?.title}</p>
                                        <p className='shop-note'>{shop?.note}</p>
                                    </div>
                                </NavLink>
                            ))
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default Shops