import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Cart.css';
import emptyCart from '../../images/empty-cart.png';
import ImageViewer from '../ImageViewer/ImageViewer';
import { calculatePrice, fetchCheckout, removeItem, saveItem, setStatus } from './cart.slice';
import edit from '../../images/edit.png';
import remove from '../../images/delete.png';
import Modal from '../Modal/Modal';
import ModalCartItem from '../ModalCartItem/ModalCartItem';
import { setItem } from '../Cart/cart.slice';
import { handleInputChange } from '../ModalCartItem/modalCartItem.slice';
import { NavLink, useLocation } from 'react-router-dom';
import Singin from '../Signin/Signin';
import loading from '../../images/loading.gif';
import { useNavigate } from 'react-router-dom';
import Shops from '../Shops/Shops';


function Cart() {
  const { items, status } = useSelector(state => state.cart);
  const dispatch = useDispatch();

  const [modalClassesItem, setModalClassesItem] = useState('modal-base-container modal-hide');
  const itemState = useSelector(state => state.cart.itemState);

  const [modalClassesSignin, setModalClassesSignin] = useState('modal-base-container modal-hide');

  const modalContainerFocusItem = (modalContainer) => {
    modalContainer.focus();
  };

  const modalContainerFocusSignin = (modalContainer) => {
    modalContainer.focus();
  };

  const showModalItem = (item) => {
    setModalClassesItem('modal-base-container');
    dispatch(setItem(item));
  };

  const showModalSignin = (item) => {
    setModalClassesSignin('modal-base-container');
  };

  const hideModalItem = () => {
    setModalClassesItem('modal-base-container modal-hide');
  };

  const hideModalSignin = () => {
    setModalClassesSignin('modal-base-container modal-hide');
  };

  const handleSaveItem = () => {
    dispatch(saveItem(itemState));
    hideModalItem();
  }

  const { token } = useSelector(state => state.user.user);
  const signinStatus = useSelector(state => state.user.signin).status;
  const navigate = useNavigate();
  useEffect(() => {
    if (status === 'link to orders') {
      dispatch(setStatus(null));
      navigate('/orders');
    }
    if (token !== null & signinStatus === null) {
      hideModalSignin();
    }
  }, [token, signinStatus, navigate, status, dispatch]);
  const handleCheckout = () => {
    if (token === null) {
      showModalSignin();
      return;
    }

    if (token !== null) {
      dispatch(fetchCheckout({ userId: token, items, status: 'sent to store', statusCode: 0, removed: false, created: Date.now(), sum: calculatePrice(items) }));
    }
  }

  const location = useLocation();
  useEffect(() => {
    document.querySelector('main').scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (location.hash !== '#modal') {
      hideModalItem();
      hideModalSignin();
    }
  }, [location.hash]);

  return (
    <div>
      {
        status === null &&
        <div className="cart-items-container">
          <div className="cart-items">
            {
              items.length > 0 &&
              <h4 className='cart-items-header'>Товары в корзине</h4>
            }
            {
              items.map((item, index) => {
                return (
                  <div key={index} className='cart-item-container'>
                    <div className='cart-item-button-container'>
                      <button className="button cart-item-button cart-edit-button" onClick={() => showModalItem(item)}><img src={edit} alt="" /></button>
                      <button className="button cart-item-button cart-delete-button" onClick={() => dispatch(removeItem(item.cartItemId))}><img src={remove} alt="" /></button>
                    </div>
                    <NavLink to={'/catalog/' + item.id} className={'item-navlink'}>
                      <div className="item-image-container">
                        <ImageViewer images={item.images} autoSlide={true} />
                      </div>
                    </NavLink>

                    <NavLink to={'/catalog/' + item.id} className={'item-navlink'}>
                      <div className="cart-item-price-container">
                        <div className="item-title-container cart-item-title">
                          <span className="item-title"><span className="cart-property-label">Товар: </span>{item.title}</span>
                          <span className="item-store"><span className="cart-property-label">Магазин: </span>{item.store}</span>
                        </div>

                        <div className='cart-sum-container'>
                          <span className="cart-sum"><span className="cart-property-label">Сумма: </span>{calculatePrice(item)}</span>
                          <span className="cart-property-label">Детали суммы:</span>
                          {
                            item.units.map((unit, index) => {
                              return (
                                <span key={index} className="cart-unit-info">{calculatePrice(item, unit)}</span>
                              )
                            })
                          }
                        </div>
                      </div>
                    </NavLink>
                  </div>
                )
              })
            }
          </div>

          {
            items.length > 0 &&
            <div className="cart-total">
              <h2>Оформление заказа</h2>
              <h4 className='total-items-count'>{items.length} наименований</h4>
              <div className="total-items-price"><span className="property-label">Итого:</span>{calculatePrice(items)}</div>
              <button className="cart-add-button purchese" onClick={() => handleCheckout()}>Оформить</button>
            </div>
          }
        </div>
      }

      {
        status === 'loading' &&
        <div className="loading-container">
          <img src={loading} alt="" style={{ height: '30px' }} />
          Загрузка
        </div>
      }

      {
        status === 'error' &&
        <div className="error cart-error">
          <p>Произошла ошибка при загрузки корзины</p>
          <button className='button error-button' onClick={() => handleCheckout()}>Повторить попытку</button>
        </div>
      }

      {
        items.length === 0 &&
        <div>
          <div className="empty-cart-container">
            <img src={emptyCart} alt="" />
            <span>Корзина пока пусто</span>
          </div>
          <Shops />
        </div>
      }

      <Modal modalContainerFocus={modalContainerFocusItem} modalClasses={modalClassesItem} hideModal={hideModalItem} style={{ width: '70%', height: 'auto', maxWidth: '900px' }} accept={handleSaveItem} onKeyDown={(evt) => dispatch(setItem(handleInputChange(evt, itemState.units.find(unit => unit.id === itemState.price.unit), itemState)))}>
        <ModalCartItem addToCart={handleSaveItem} itemState={itemState} setItem={setItem} submitText='Сохранить' />
      </Modal>

      <Modal modalContainerFocus={modalContainerFocusSignin} modalClasses={modalClassesSignin} hideModal={hideModalSignin} style={{ width: '70%', height: 'auto', maxWidth: '900px' }}>
        <Singin />
      </Modal>
    </div>
  )
}

export default Cart