import { createSlice } from "@reduxjs/toolkit";
import home from '../../images/home.png';
import clips from '../../images/clips.png';
import cart from '../../images/cart.png';
import user from '../../images/user.png';
import search from '../../images/search.png';

const menuSlice = createSlice({
    name: "menu",
    initialState: {
        items: [
            {
                id: 'home',
                img: {
                    src: home,
                    alt: 'home',
                },
                title: 'Главная',
                className: 'menuButton',
                type: 'topButton',
            },
            {
                id: 'search',
                img: {
                    src: search,
                    alt: 'search',
                },
                title: 'Поиск',
                className: 'menuButton',
                type: 'topButton',
            },
            {
                id: 'clips',
                img: {
                    src: clips,
                    alt: 'clips',
                },
                title: 'Клипы',
                className: 'menuButton',
                type: 'topButton',
            },
            {
                id: 'cart',
                img: {
                    src: cart,
                    alt: 'cart',
                },
                title: 'Корзина',
                className: 'menuButton',
                type: 'topButton',
            },
            {
                id: 'user',
                img: {
                    src: user,
                    alt: 'user',
                },
                title: 'Пользователь',
                className: 'menuButton',
                type: 'bottomButton',
            },
        ],
        status: null,
        error: null
    },
    reducers: {
        setItemBadge: (state, {payload: pItem}) => {
            state.items = state.items.map(item => {
                if (item.id !== pItem.id) {
                    return item;
                }

                return {
                    ...item,
                    badge: pItem.badge,
                }
            });
        }
    },
});

export const { setItemBadge } = menuSlice.actions;
export default menuSlice.reducer;