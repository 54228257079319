import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchBadgeValue } from './BadgeButton.slice';
import './BadgeButton.css';

function BadgeButton({ children, badgeValueUrl, link, badge }) {
    const badgeValue = useSelector(state => state.badgeButton[badge]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchBadgeValue(badgeValueUrl));
    }, [dispatch, badgeValueUrl]);
    return (
        <div className="user-button-container">
            <NavLink to={link} className="button user-button user-button-badge">
                {children} {badgeValue > 0 && <span className='badge'>{badgeValue}</span>}
            </NavLink>
        </div>
    )
}

export default BadgeButton