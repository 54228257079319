import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber, setSignout } from './user.slice';
import Signin from '../Signin/Signin';
import './User.css';
import BadgeButton from '../BadgeButton/BadgeButton';
function User() {
  const dispatch = useDispatch();
  const { user, status } = useSelector(state => state.user);
  const token = user.token || localStorage.getItem('token');
  const signinStatus = useSelector(state => state.user.signin.status);

  return (
    <div className='user-container'>
      {
        ((!token || signinStatus !== null) && status !== 'error' && status !== 'loading') &&
        <div>
          <Signin />
        </div>
      }
      {
        status === 'loading' &&
        <div className="user-information-container">
          <div className="user-information">
            <p className='user-name loading user-loading'> </p>
            <p className='user-phone loading user-loading'> </p>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
          <div className="user-action-buttons loading user-loading">
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
            <div className="user-button-container">
              <p className="user-button"></p>
            </div>
          </div>
        </div>
      }
      {
        status === 'success' &&
        <div className="user-information-container">
          <div className="user-information">
            <p className='user-name'>{user.name}</p>
            <p className='user-phone'>{formatPhoneNumber(user.phone.number, user.country, true)}</p>
          </div>
          <div className="user-action-buttons">
            <BadgeButton badge='ordersCount' badgeValueUrl={'/api/ordersCount?token=' + token} link={'/orders'}>Заказы</BadgeButton>
            <BadgeButton badge='shopsCount' badgeValueUrl={'/api/shopsCount?token=' + token} link={'/shops'}>Магазины</BadgeButton>            
          </div>
          <div className="user-action-buttons">
            <div className="user-button-container">
              <button className="button user-button user-button-red" onClick={() => dispatch(setSignout())}>Выйти из аккаунта</button>
            </div>
          </div>
        </div>
      }
      {
        status === 'error' &&
        <div className="error">Произошла ошибка при загрузки страницы, попробуйте перегрузить страницу</div>
      }
    </div>
  )
}

export default User