import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchShop } from './shop.slice';
import { useLocation } from 'react-router-dom';
import './Shop.css';
import Items from '../Items/Items';
import background from '../../images/background.png';
import shopAvatar from '../../images/shop-avatar.png';
import ImageComponent from '../ImageComponent/ImageComponent';
import { host } from '../../App';

function Shop() {
    const dispatch = useDispatch();
    const { shop, status } = useSelector((state) => state.shop);
    const location = useLocation();
    const pathnames = location.pathname.split('/');
    const shopId = pathnames.pop();
    useEffect(() => {
        dispatch(fetchShop(shopId));
    }, [dispatch, shopId]);
    useEffect(() => {
        document.querySelector('main').scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <div>
            {
                status === 'loading' &&
                <div className='shop-page-container'>
                    <div className="shop-header">
                        <div className="shop-page-background-container loading">

                        </div>
                        <div className="shop-page-info-container">
                            <div className="shop-page-main-image">

                            </div>
                            <div className="shop-page-info-properties">
                                <div className="shop-title loading shop-page-loading"></div>
                                <div className="shop-note loading shop-page-loading"></div>
                            </div>
                        </div>
                    </div>
                    <div className="shop-items-container">
                        <h3>Товары</h3>
                        <Items itemsState={{ items: [], status: 'loading' }} />
                    </div>
                </div>
            }
            {
                status === 'error' &&
                <div className='error'>
                    Произошла ошибка во время загрузки страницы, пожалуйста, попробуйте обновить страницу
                </div>
            }
            {
                status === 'success' &&
                <div className='shop-page-container'>
                    <div className="shop-header">
                        <div className="shop-page-background-container">
                            <ImageComponent src={shop?.images?.find(image => image.type === 'background') ? host.url + '/api/' + shop?.images?.find(image => image.type === 'background')?.src : background} alt="" />
                        </div>
                        <div className="shop-page-info-container">
                            <div className="shop-page-main-image">
                                <ImageComponent src={shop?.images?.find(image => image.type === 'main') ? host.url + '/api/' + shop?.images?.find(image => image.type === 'main')?.src : shopAvatar} alt="" />
                            </div>
                            <div className="shop-page-info-properties">
                                <div className="shop-title">{shop?.title}</div>
                                <div className="shop-note">{shop?.note}</div>
                            </div>
                        </div>
                    </div>
                    {
                        shop?.items?.length > 0 &&
                        <div className="shop-items-container">
                            <h3>Товары</h3>
                            <Items itemsState={{ items: shop?.items || [], status: 'success' }} longNavLink={true} />
                        </div>
                    }
                    {
                        shop?.items?.length === 0 &&
                        <div className="no-items">
                            <h3>Товары</h3>
                            <p className='description'>Товары отсутствуют</p>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Shop