import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchUser = createAsyncThunk("user/fetchUser", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(host.url + '/api/user?token=' + parameters);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchSignin = createAsyncThunk("user/fetchSignin", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(host.url + '/api/signin', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(parameters),
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: {
            name: null,
            phone: null,
            token: null,
        },
        signin: {
            status: null, //null | code sent | code already sent | wrong code | success | loading | error
            error: null,
        },
        status: null,
        error: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setSignin: (state, action) => {
            state.signin = action.payload;
        },
        setSignout: (state) => {
            state.user = {
                name: null,
                phone: null,
                token: null,
            };
            state.status = null;
            state.error = null;
            localStorage.removeItem("token");
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.status = "success";
                state.error = null;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.status = "error";
                state.error = action.payload;
            });

        builder
            .addCase(fetchSignin.pending, (state, action) => {
                state.signin.status = "loading";
                state.signin.error = null;
            })
            .addCase(fetchSignin.fulfilled, (state, action) => {
                state.signin.status = action.payload.status; //code sent | wrong code
                if (action.payload.token) {
                    localStorage.setItem("token", action.payload.token);
                    state.user.token = action.payload.token;
                }
                if (action.payload.name) {
                    state.user.name = action.payload.name;
                }
                state.signin.error = null;
            })
            .addCase(fetchSignin.rejected, (state, action) => {
                state.signin.status = "error";
                state.signin.error = action.payload;
            });
    },
});

export const formatPhoneNumber = (phoneNumber, country = '7', withCountryCode = false) => {
    if (!phoneNumber) {
        return null;
    }
    const input = phoneNumber.replace(/\D/g, '');
    let formattedNumber = input;
    if (input.length > 0) {
        formattedNumber = '(' + input.substring(0, 3);
    }
    const secondPart = country.length === 2 ? 5 : 6;
    if (input.length > 3) {
        formattedNumber = '(' + input.substring(0, 3) + ') ' + input.substring(3, secondPart);
    }
    const thirdPart = country.length === 2 ? 7 : 8;
    if (input.length > secondPart) {
        formattedNumber = '(' + input.substring(0, 3) + ') ' + input.substring(3, secondPart) + '-' + input.substring(secondPart, thirdPart);
    }
    if (input.length > thirdPart) {
        formattedNumber = '(' + input.substring(0, 3) + ') ' + input.substring(3, secondPart) + '-' + input.substring(secondPart, thirdPart) + '-' + input.substring(thirdPart, 10);
    }
    return withCountryCode ? '+' + country + ' ' + formattedNumber : formattedNumber;
};

export default userSlice.reducer;
export const { setUser, setSignin, setSignout } = userSlice.actions;