import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchSlides = createAsyncThunk("slideshow/fetchSlides", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/slides`);

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const slideshowSlice = createSlice({
    name: 'slideshow',
    initialState: {
        slides: [],
        status: null,
        error: null,
    },
    reducers: {
        setSlides: (state, action) => {
            state.slides = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSlides.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            });
        builder
            .addCase(fetchSlides.fulfilled, (state, action) => {
                state.slides = action.payload;
                state.error = null;
                state.status = "success";
            });
        builder
            .addCase(fetchSlides.rejected, (state, action) => {
                state.status = "error";
                state.error = action.payload;
            });
    },
});

export const { setSlides } = slideshowSlice.actions;
export default slideshowSlice.reducer;