import React, { useState } from 'react';
import logo from '../../logo-small.png';
import './Menu.css';
import menu from '../../images/menu.png';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Menu({ isLeftMenu }) {
    const [showed, setShow] = useState('LeftMenu-container');
    const menuButtons = useSelector(state => state.menu.items);
    const showHide = () => {
        if (showed === 'LeftMenu-container' | showed === 'LeftMenu-container hide') {
            setShow('LeftMenu-container show');
            document.getElementsByClassName('Layout-container')[0].style.gridTemplateColumns = '200px 1fr';
            return;
        }

        setShow('LeftMenu-container hide');
        document.getElementsByClassName('Layout-container')[0].style.gridTemplateColumns = '50px 1fr';
    };

    const leftMenu = () => {
        return (
            <div className={showed}>
                <div className='iconContainer'>
                    <img src={logo} alt="zardolu" id='leftMenuIcon' width={26} />
                    <span className='buttonTitle'>Зардолу</span>
                </div>

                <div className='topButtons'>
                    <button className='menuButton menuIcon' onClick={showHide}>
                        <img src={menu} alt="menu" width={26} />
                    </button>
                    {
                        menuButtons.map(button => {
                            if (button.type === 'bottomButton') {
                                return null;
                            }
                            return (
                                <NavLink to={'/' + (button.img.alt === 'home' ? '' : button.img.alt)} key={button.img.alt} id={button.img.alt} className={button.className}>
                                    <div className='menu-item-icon-container'>
                                        <span className={button?.badge > 0 ? 'buttonBadge activeBadge' : 'buttonBadge'}>{button?.badge}</span>
                                        <img src={button.img.src} alt={button.img.alt} width={26} />
                                    </div>
                                    <span className='buttonTitle'>{button.title}</span>
                                </NavLink>);
                        })
                    }
                </div>
                <div className='bottomButtons'>
                    {
                        menuButtons.map(button => {
                            if (button.type === 'topButton') {
                                return null;
                            }
                            return (
                                <NavLink to={'/' + (button.img.alt === 'home' ? '' : button.img.alt)} key={button.img.alt} id={button.img.alt} className={button.className}>
                                    <div className='menu-item-icon-container'>
                                        <span className={button?.badge > 0 ? 'buttonBadge activeBadge' : 'buttonBadge'}>{button?.badge}</span>
                                        <img src={button.img.src} alt={button.img.alt} width={26} />
                                    </div>
                                    <span className='buttonTitle'>{button.title}</span>
                                </NavLink>);
                        })
                    }
                </div>
            </div>
        )
    };

    const bottomMenu = () => {
        return (
            <div className='bottomMenu'>
                {
                    menuButtons.map(button => {
                        return (
                            <NavLink to={'/' + (button.img.alt === 'home' ? '' : button.img.alt)} key={button.img.alt} id={button.img.alt} className={button.className}>
                                <div className='menu-item-icon-container'>
                                    <span className={button?.badge > 0 ? 'buttonBadge activeBadge' : 'buttonBadge'}>{button?.badge}</span>
                                    <img src={button.img.src} alt={button.img.alt} width={26} />
                                </div>
                                <span className='buttonTitle'>{button.title}</span>
                            </NavLink>);
                    })
                }
            </div>
        )
    };

    return (
        <>
            {isLeftMenu ? leftMenu() : bottomMenu()}
        </>
    )
}

export default Menu