import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from './orders.slice';
import { NavLink } from 'react-router-dom';
import './Orders.css';

function Orders() {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const { orders, status } = useSelector(state => state.orders);

  useEffect(() => {
    dispatch(fetchOrders(token))
  }, [dispatch, token]);

  return (
    <div>
      {
        status === 'loading' &&
        <div>
          <h3 className='order-item-container'>Заказы</h3>
          {
            Array.from({ length: 10 }).map((_, i) => <p key={i} className='order-item-container loading order-loading'></p>)
          }
        </div>
      }
      {
        status === 'error' &&
        <div className='error'>Произошла ошибка во время загрузки страницы, пожалуйста, попробуйте обновить  страницу</div>
      }
      {
        status === 'success' &&
        <div>
          <h3 className='order-item-container'>Заказы</h3>
          {
            orders.map((order) => (
              <div key={order._id} className={'order-item-container'}>
                <NavLink to={`/order/${order._id}`} className={'order-item'}>
                  <p>{order.items.length} наименований из {order.stores.length} магазинов</p>
                  <p>Номер заказа: <span>{order._id}</span></p>
                  <p>Статус: <span className={'order-status order-status' + (order.statusCode ?? 0)}>{order.status}</span></p>
                  <p>Дата заказа: <span>{new Date(order.created).toLocaleString()}</span></p>
                  <p className='order-sum'>Сумма: <span>{order.sum}</span></p>
                </NavLink>
              </div>
            ))
          }
          {
            orders.length === 0 && <p className='order-item-container description'>Пока нет заказов</p>
          }
        </div>
      }
    </div>
  )
}

export default Orders