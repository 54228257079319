import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchShops = createAsyncThunk("shops/fetchShops", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/shops`);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const shopsSlice = createSlice({
    name: "shops",
    initialState: {
        shops: [],
        status: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchShops.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchShops.fulfilled, (state, action) => {
                state.status = "success";
                state.shops = action.payload;
            })
            .addCase(fetchShops.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            });
    },
});

export default shopsSlice.reducer;
