import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchShop = createAsyncThunk("shop/fetchShop", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(host.url + '/api/shop/' + parameters);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const shopSlice = createSlice({
    name: "shop",
    initialState: {
        shop: {},
        status: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchShop.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchShop.fulfilled, (state, action) => {
                state.shop = action.payload;
                state.error = null;
                state.status = "success";
            })
            .addCase(fetchShop.rejected, (state, action) => {
                state.error = action.payload;
                state.status = "error";
            });
    }
});

export default shopSlice.reducer;