import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchCategories = createAsyncThunk("search/fetchCategories", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/categories`);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchSearch = createAsyncThunk("search/fetchSearch", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/search?search=${parameters}`);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const searchSlice = createSlice({
    name: "search",
    initialState: {
        search: {},
        categories: [],
        status: null,
        error: null,
    },
    reducers: {
        setStatus: (state, { payload: status }) => {
            state.status = status;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.status = "success";
                state.categories = action.payload;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            });

        builder
            .addCase(fetchSearch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchSearch.fulfilled, (state, action) => {
                state.status = "search";
                state.search = action.payload;
            })
            .addCase(fetchSearch.rejected, (state, action) => {
                state.status = "error";
                state.error = action.error.message;
            });
    },
});

export default searchSlice.reducer;
export const { setStatus } = searchSlice.actions;