import React, { useEffect, useRef, useState } from 'react';
import './Search.css';
import searchIcon from '../../images/search.png';
import close from '../../images/close.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, fetchSearch } from './search.slice';
import Items from '../Items/Items';
import Shops from '../Shops/Shops';

function Search() {
  const dispatch = useDispatch();
  const { categories, status, search } = useSelector(state => state.search);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const closeIconRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const handleInputChange = (e) => {
    if (e.target.value === '') {
      closeIconRef.current.classList.add('hideSearch');
      if (status === 'search') {
        dispatch(fetchCategories(e.target.value));
      }
    } else {
      closeIconRef.current.classList.remove('hideSearch');
    }
    setSearchValue(e.target.value);
  }

  const handleSearch = () => {
    if (searchValue.length > 2) {
      dispatch(fetchSearch(searchValue));
    }
  }


  return (
    <div>
      <form onSubmit={e => { e.preventDefault(); handleSearch() }}>
        <div className='search-input-container'>
          <input type="text" placeholder='Напишите, что вы ищете. Например: Курага, Кешью 180, Кедровый орех...' value={searchValue} onChange={handleInputChange} />
          <div ref={closeIconRef} className="search-close-icon hideSearch" onClick={() => { setSearchValue(''); closeIconRef.current.classList.add('hideSearch'); dispatch(fetchCategories()) }}>
            <img src={close} alt="" />
          </div>
          <div className="search-icon" onClick={() => handleSearch()}>
            <img src={searchIcon} alt="" />
          </div>
        </div>
      </form>
      <div className="categories-container">
        {
          status === 'success' &&
          Object.entries(categories).map(([category, items], index) => (
            <div className="category-container" key={index}>
              <h3>{category?.toUpperCase()}</h3>
              <Items itemsState={{ items, status: 'success' }} longNavLink={true} lineItems={true} />
            </div>
          ))
        }
        {
          status === 'loading' &&
          <div className="categories-container">
            <div className="category-container">
              <p className='loading search-loading'></p>
              <Items itemsState={{ items: Array.from({ length: 10 }), status: 'loading' }} longNavLink={true} lineItems={true} />
            </div>
            <div className="category-container">
              <p className='loading search-loading'></p>
              <Items itemsState={{ items: Array.from({ length: 10 }), status: 'loading' }} longNavLink={true} lineItems={true} />
            </div>
          </div>
        }
        {
          status === 'error' &&
          <div className="error">Произошла ошибка во время загрузки страницы, пожалуйста, попробуйте обновить  страницу</div>
        }
        {
          status === 'search' &&
          <div className="search-results-container">
            {
              (search?.items?.length === 0 && search?.users?.length === 0 && search?.shops?.length === 0) &&
              <p className='description'>По вашему запросу ничего не найдено</p>
            }
            {
              search?.items?.length > 0 &&
              <div className="items-container">
                <h3>Товары</h3>
                <Items itemsState={{ items: search?.items, status: 'success' }} longNavLink={true} />
              </div>
            }
            {
              search?.shops?.length > 0 &&
              <div className="shops-container">
                <Shops shopsProps={search?.shops} statusProps={'success'} />
              </div>
            }
          </div>
        }
      </div>

    </div>
  )
}

export default Search