import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";
export const fetchItem = createAsyncThunk("catalog/fetchItem", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/catalog/${parameters}`);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const catalogSlice = createSlice({
    name: "catalog",
    initialState: {
        item: {},
        status: null,
        error: null,
    },
    reducers: {
        setItem: (state, action) => {
            state.item = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchItem.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchItem.fulfilled, (state, action) => {
                state.item = action.payload;
                state.status = "success";
                state.error = null;
            })
            .addCase(fetchItem.rejected, (state, action) => {
                state.status = "error";
                state.error = action.payload;
            });
    },
});

export default catalogSlice.reducer;
export const { setItem } = catalogSlice.actions;
