import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App"; 

export const fetchBadgeValue = createAsyncThunk("badgeButton/fetchBadgeValue", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(host.url + parameters);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const badgeButtonSlice = createSlice({
    name: 'badgeButton',
    initialState: {
        
    },
    reducers: {
        setBadge(state, action) {
            state.badge = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBadgeValue.fulfilled, (state, action) => {
                state[action.payload.key] = Number(action.payload.value);
            });
    },
});

export const { setBadge } = badgeButtonSlice.actions;
export default badgeButtonSlice.reducer;