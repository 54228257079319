import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/Home/Home';
import Clips from './components/Clips/Clips';
import Cart from './components/Cart/Cart';
import User from './components/User/User';
import Faq from './components/Faq/Faq';
import NotFound from './components/NotFound/NotFound';

import Layout from './components/Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setItemBadge } from './components/Menu/menu.slice';
import Catalog from './components/Catalog/Catalog';
import Orders from './components/Orders/Orders';
import Search from './components/Search/Search';
import Order from './components/Order/Order';
import Shops from './components/Shops/Shops';
import Shop from './components/Shop/Shop';

function App() {
  const dispatch = useDispatch();
  const cartItemsLength = useSelector(state => state.cart.items.length);
  useEffect(() => {
    dispatch(setItemBadge({ id: 'cart', badge: cartItemsLength }));
  }, [dispatch, cartItemsLength]);

  return (
    <div className="App" onContextMenu={(e) => e.preventDefault()}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='user' element={<User />} />
          <Route path='clips/*' element={<Clips />} />
          <Route path='cart' element={<Cart />} />
          <Route path='faq' element={<Faq />} />
          <Route path='catalog/*' element={<Catalog />} />
          <Route path='orders' element={<Orders />} />
          <Route path='order/*' element={<Order />} />
          <Route path='shops' element={<Shops />} />
          <Route path='shop/*' element={<Shop />} />
          <Route path='search/*' element={<Search />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export const host = {
  url: "https://zardolu.ru",
};
export default App;
