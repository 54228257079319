import { React, useRef, useLayoutEffect, useEffect } from 'react';
import './Modal.css';
import closeButtonImage from '../../images/close.png';
import minusButton from '../../images/minus.png';
import { useLocation, useNavigate } from 'react-router-dom';

function Modal({ children, modalClasses, accept, hideModal, style, modalContainerFocus, onKeyDown }) {
    const modalBaseContainer = useRef();
    const modalContainer = useRef();

    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (modalClasses === 'modal-base-container') {
            navigate('#modal');
        }
    }, [modalClasses, navigate]);

    useLayoutEffect(() => {
        if (modalContainer.current) {
            modalContainerFocus(modalContainer.current);
        }
    });

    const downClick = () => {
        modalContainer.current.classList.add('modal-down');
        setTimeout(() => {
            hideModal();
            modalContainer.current.classList.remove('modal-down');
        }, 200);
    }

    const closeModal = (evt) => {
        if (evt.target.classList.value === 'button modal-close-button' | evt.target.classList.value === 'modal-close-icon' | evt.target.classList.value === 'modal-base-container') {
            navigate(location.pathname);
            if (window.screen.width <= 600) {
                modalContainer.current.classList.add('modal-down');
                setTimeout(() => {
                    hideModal();
                    modalContainer.current.classList.remove('modal-down');
                }, 200);
                return;
            }
            hideModal();
        }
    };

    const keyDown = (evt) => {
        if (evt.key === 'Escape') {
            hideModal();
        }

        if (evt.key === 'Enter') {
            if (evt.target.className === 'modal-container') {
                accept();
                return;
            }
            evt.target.focus();
        }

        if (typeof onKeyDown === 'function') {
            onKeyDown(evt);
        }
    }

    return (
        <div ref={modalBaseContainer} className={modalClasses} onClick={closeModal}>
            <div ref={modalContainer} className="modal-container" tabIndex={0} style={style} onKeyDown={keyDown}>
                <div className="modal-main">
                    <div className="modal-header">
                        <button className="button modal-minus-button" onClick={downClick} onTouchStart={downClick}><img src={minusButton} alt="" className='modal-minus-icon' /></button>
                        <span className="modal-title"></span>
                        <button className="button modal-close-button" onClick={closeModal}>
                            <img className='modal-close-icon' src={closeButtonImage} alt="" />
                        </button>
                    </div>
                    {children}
                </div>
                <div className="modal-footer"></div>
            </div>
        </div>
    )
}

export default Modal