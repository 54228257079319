import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchOrders = createAsyncThunk("orders/fetchOrders", async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api/orders?token=${parameters}`);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

const ordersSlice = createSlice({
    name: "orders",
    initialState: {
        orders: [],
        status: null,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.orders = action.payload;
                state.status = "success";
                state.error = null;
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.status = "error";
                state.error = action.payload;
            });
    },
});
export default ordersSlice.reducer;