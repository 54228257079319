import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import './Slideshow.css';
import left from '../../images/left.png';
import right from '../../images/right.png';

let slideIndex = 0;

const Slideshow = ({ slidesState }) => {
    const { slides, status } = slidesState;
    const slideshowContainerRef = useRef(null);
    const slidesContainerRef = useRef(null);
    const img = useRef();

    const [width, setWidth] = useState(0);
    const [slideDots, setDot] = useState(slides?.map((slide, index) => index === 0 ? true : false));


    const resize = () => {
        if (!slideshowContainerRef.current) {
            return;
        }

        const containerWidth = slideshowContainerRef.current.getBoundingClientRect().width;

        setWidth(containerWidth);
        const slideShowItems = document.getElementsByClassName('slideshow-item');
        Array.from(slideShowItems).forEach(element => {
            element.style.width = width + 'px';
        });

        slidesContainerRef.current && slidesContainerRef.current.animate(
            [
                { transform: 'translateX(' + (-1 * slideIndex * width) + 'px)' },
            ],
            {
                duration: 200,
                easing: 'ease',
                fill: 'forwards',
            }
        );

        if (img.current) {
            if (img.current.getBoundingClientRect().height < slideshowContainerRef.current.getBoundingClientRect().height) {
                const imgEls = document.querySelectorAll('.slideshow-item img');
                for (let i = 0; i < imgEls.length; i++) {
                    imgEls[i].style.width = 'auto';
                    imgEls[i].style.height = '100%';
                }
            }

            if (img.current.getBoundingClientRect().width < slideshowContainerRef.current.getBoundingClientRect().width) {
                const imgEls = document.querySelectorAll('.slideshow-item img');
                for (let i = 0; i < imgEls.length; i++) {
                    imgEls[i].style.width = '100%';
                    imgEls[i].style.height = 'auto';
                }
            }
        }
    }

    useLayoutEffect(() => {
        resize();
    });

    useEffect(() => {
        const interval = setInterval(() => {
            slide({ currentTarget: { id: 'rightButton' } });
        }, 5000);

        const sInterval = setInterval(resize, 200);

        return () => {
            clearInterval(interval);
            clearInterval(sInterval);
        }
    });



    const slide = (evt) => {
        const side = evt.currentTarget.id === 'leftButton' ? 'left' : 'right';

        if (!slidesContainerRef.current) {
            return;
        }

        if (side === 'right') {
            slideIndex = slideIndex + 1 === slides?.length ? 0 : slideIndex + 1;
            slidesContainerRef.current.animate(
                [
                    { transform: 'translateX(' + (-1 * slideIndex * width) + 'px)' },
                ],
                {
                    duration: 200,
                    easing: 'ease',
                    fill: 'forwards',
                }
            );
        } else {
            slideIndex = slideIndex === 0 ? slides.length - 1 : slideIndex - 1;

            slidesContainerRef.current.animate(
                [
                    { transform: 'translateX(' + (-1 * slideIndex * width) + 'px)' },
                ],
                {
                    duration: 200,
                    easing: 'ease',
                    fill: 'forwards',
                }
            );
        }

        setDot(slideDots.map((slide, index) => index === slideIndex ? true : false));
    };

    let startX;
    let endX;

    const touchStart = (event) => {
        startX = event.touches[0].clientX;
    };

    const touchMove = (event) => {
        endX = event.touches[0].clientX;
    };

    const touchEnd = () => {
        if (startX - endX > 30) {
            slide({ currentTarget: { id: 'rightButton' } });
        } else if (endX - startX > 30) {
            slide({ currentTarget: { id: 'leftButton' } });
        }
    };

    const slideClick = () => {
        const slide = slides[slideIndex];
        if (slide.link !== undefined) {
            window.open(slide.link, '_blank');
        }
    }

    return (
        <div ref={slideshowContainerRef} className='slideshow-container'>
            <div className="slideshow-border" onTouchStart={touchStart} onTouchMove={touchMove} onTouchEnd={touchEnd} onClick={slideClick}></div>
            {
                status === 'loading' &&
                <>
                    <div className="slides">
                        <div className="slides-container">
                            <div className="slideshow-item loading"></div>
                        </div>
                    </div>
                </>
            }

            {
                status === 'success' &&
                <>
                    <div className="slides">
                        <div ref={slidesContainerRef} className='slides-container'>
                            {
                                slides?.map(slide => {
                                    return (
                                        <a href={slide.link ?? '#'} key={slide.id} className='slideshow-item'>
                                            <img ref={img} src={slide.img.src} alt={slide.img.alt} style={{ height: '100% !important' }} />
                                        </a>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <button className="slideshow-button" onClick={slide} id='leftButton'>
                        <img src={left} alt="left" />
                    </button>
                    <button className="slideshow-button" onClick={slide} id='rightButton'>
                        <img src={right} alt="right" />
                    </button>
                    <div className="slideshow-dots">
                        {
                            slideDots?.map((slide, index) => {return <span key={index} className={slide ? 'dot activeDot' : 'dot'}>●</span>})
                        }
                    </div>
                </>
            }

            {
                status === 'error' &&
                <div className="slides">
                    <div className='slides-container'>
                        <p className='slideshow-item error'>Не удалось загрузить слайды</p>
                    </div>
                </div>
            }
        </div>
    );
};

export default Slideshow;