import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { host } from "../../App";

export const fetchItems = createAsyncThunk('clips/fetchItems', async (parameters, { rejectWithValue }) => {
    try {
        const response = await fetch(`${host.url}/api${parameters}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
})

const clipsSlice = createSlice({
    name: "clips",
    initialState: {
        items: [],
        itemState: null,
        status: null,
        error: null,
    },
    reducers: {
        setAutoPlay: (state, { payload: index }) => {
            state.items = state.items.map((item, i) => {
                if (index === i) {
                    return {
                        ...item,
                        autoPlay: true,
                    }                    
                }
                return {
                    ...item,
                    autoPlay: false,
                }
            });
        },
        setItem: (state, action) => {
            state.itemState = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchItems.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchItems.fulfilled, (state, action) => {
                state.status = "success";
                if (action.payload && action.payload.length > 0) {
                    state.items = action.payload.map((item, index) => {
                        if (index === 0) {
                            return {
                                ...item,
                                autoPlay: true,
                            }
                        }
                        return {
                            ...item,
                            autoPlay: false,
                        }
                    });                    
                }
            })
            .addCase(fetchItems.rejected, (state, action) => {
                state.status = "error";
                state.error = action.payload;
            });
    },
});

export default clipsSlice.reducer;
export const { setAutoPlay, setItem } = clipsSlice.actions;