import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Menu from '../Menu/Menu';
import './Layout.css';
import { useDispatch } from 'react-redux';
import { fetchItems } from '../Items/items.slice';
import { fetchSlides } from '../Slideshow/slideshow.slice';
import { fetchUser } from '../User/user.slice';

function Layout() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchItems());
        dispatch(fetchSlides());
        const token = localStorage.getItem('token');
        if (token) {
            dispatch(fetchUser(token));
        }
    }, [dispatch]);

    return (
        <div className='Layout-container'>
            <aside className='aside'>
                <Menu isLeftMenu={true} />
            </aside>
            <main className='main'>
                <Outlet />
            </main>
            <footer>
                <Menu isLeftMenu={false} />
            </footer>
        </div>
    )
}

export default Layout