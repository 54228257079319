import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrder } from './order.slice';
import { NavLink, useLocation } from 'react-router-dom';
import './Order.css';
import ImageViewer from '../ImageViewer/ImageViewer';
import { calculatePrice } from '../Cart/cart.slice';
import Catalog from '../Catalog/Catalog';

function Order() {
    const token = localStorage.getItem('token');
    const location = useLocation();
    const currentURL = location.pathname;
    const orderId = currentURL.split('/').pop();
    const itemId = location.search?.split('=')?.[1];    
    const { order, status } = useSelector(state => state.order);
    const dispatch = useDispatch();
    useEffect(() => { 
        dispatch(fetchOrder({ orderId, token }));       
    }, [dispatch, orderId, token]);
    
    let item = undefined;
    if (itemId !== undefined && status === 'success') {
        item = order.items.find(i => Number(i.id) === Number(itemId));
    }

    return (
        <div className='order-info-container'>
            {
                (status === 'loading') &&
                <div>
                    <div className="catalog-item-info-container">
                        <p className='catalog-item-relations loading order-loading-p'></p>
                        <div className="order-properties-container loading order-loading-p">
                            <p className='catalog-item-info loading order-loading-p'></p>
                            <p className='catalog-item-info loading order-loading-p'></p>
                            <p className='catalog-item-info loading order-loading-p'></p>
                            <p className='catalog-item-info loading order-loading-p'></p>
                        </div>
                    </div>
                    <div>
                        <p className='catalog-item-relations loading order-loading-p'></p>
                        {
                            Array.from({ length: 10 }).map((_, i) => <p key={i} className='loading order-loading'></p>)
                        }
                    </div>
                </div>
            }
            {
                status === 'error' &&
                <div className='error'>Произошла ошибка при загрузке заказа, попробуйте обновить страницу</div>
            }
            {
                (status === 'success' && item === undefined) &&
                <div>
                    <div className="catalog-item-info-container">
                        <p className='catalog-item-relations'>Характеристики заказа</p>
                        <div className="order-properties-container">
                            <p className='catalog-item-info'><span className='catalog-item-propertyName'>Номер заказа: </span>{order._id}</p>
                            <p className='catalog-item-info'><span className='catalog-item-propertyName'>Дата заказа: </span>{new Date(order.created).toLocaleString()}</p>
                            <p className='catalog-item-info'><span className='catalog-item-propertyName'>Статус: </span><span className={'order-status order-status' + (order.statusCode ?? 0) + ' order-status-text'}>{order.status}</span></p>
                            <p className='catalog-item-info'><span className='catalog-item-propertyName'>Сумма заказа: </span>{order.sum}</p>
                        </div>
                    </div>
                    <div className="catalog-item-info-container order-title-container">
                        <p className='catalog-item-relations'>Товары</p>
                        {
                            order.items.map((item, index) => (
                                <div key={index} className='cart-item-container'>
                                    <NavLink to={'?item=' + item.id} className={'item-navlink-order'}>
                                        <div className="item-image-container">
                                            <ImageViewer images={item.images} autoSlide={true} />
                                        </div>
                                    </NavLink>

                                    <NavLink to={'?item=' + item.id} className={'item-navlink-order'}>
                                        <div className="cart-item-price-container">
                                            <div className="item-title-container cart-item-title">
                                                <span className="item-title"><span className="cart-property-label">Товар: </span>{item.title}</span>
                                                <span className="item-store"><span className="cart-property-label">Магазин: </span>{item.store}</span>
                                            </div>

                                            <div className='cart-sum-container'>
                                                <span className="cart-sum"><span className="cart-property-label">Сумма: </span>{calculatePrice(item)}</span>
                                                <span className="cart-property-label">Детали суммы:</span>
                                                {
                                                    item.units.map((unit, index) => {
                                                        return (
                                                            <span key={index} className="cart-unit-info">{calculatePrice(item, unit)}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            ))
                        }
                    </div>
                </div>
            }
            {
                item !== undefined &&
                <Catalog itemState  ={item} />
            }
        </div>
    )
}

export default Order