import { React, useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import ImageViewer from '../ImageViewer/ImageViewer';

import './Items.css';
import cart from '../../images/cart.png';

import { addItem, splitValue } from '../Cart/cart.slice';
import { useDispatch, useSelector } from 'react-redux';
import ModalCartItem from '../ModalCartItem/ModalCartItem';
import { handleInputChange } from '../ModalCartItem/modalCartItem.slice';
import { setItem } from './items.slice';
import { NavLink, useLocation } from 'react-router-dom';

function Items({ itemsState, shortNavLink, longNavLink, lineItems }) {
    const { items, status } = itemsState;
    const [modalClasses, setModalClasses] = useState('modal-base-container modal-hide');
    const itemState = useSelector(state => state.items.itemState);
    const dispatch = useDispatch();
    const host = window.location.origin;
    const modalContainerFocus = (modalContainer) => {
        modalContainer.focus();
    };
    const showModal = (item) => {
        setModalClasses('modal-base-container');
        dispatch(setItem(item));
    };

    const hideModal = () => {
        setModalClasses('modal-base-container modal-hide');
    };

    const location = useLocation();
    useEffect(() => {
        if (location.hash !== '#modal') {
            hideModal();
        }
    }, [location.hash]);

    const addToCart = (evt) => {
        dispatch(addItem(itemState));
        hideModal();
    }

    return (
        <div className='items-container'>
            <div className={'items' + (lineItems ? ' line-items' : '')}>
                {
                    status === 'loading' &&
                    Array.from({ length: 20 }).map((item, index) => {
                        return (
                            <div className="item-container loading" key={index}>
                                <div className="item-image-container loading">
                                </div>
                                <div className="item-price-container loading">
                                    <span className="item-price loading"></span>
                                </div>
                                <div className='item-title-add-cart-container loading'>
                                    <div className="item-title-container"></div>
                                </div>
                            </div>
                        )
                    })
                }

                {
                    status === 'success' &&
                    items.map(item => {
                        return (
                            <div key={item.id} className='item-container'>
                                <NavLink to={`${!shortNavLink ? longNavLink ? host + '/catalog/' : 'catalog/' : ''}${item.id}`} className={'item-navlink'}>
                                    <div className="item-image-container">
                                        <ImageViewer images={item.images} autoSlide={true} autoPlay={true} />
                                    </div>
                                    <div className="item-price-container">
                                        <span className="item-price">{splitValue(item.price?.price)}
                                            <span className='price-currency'> {item.price?.currency ?? '₽'}</span>
                                            <span className="unit"> {item?.units?.find(unit => unit.id === item.price?.unit).unit ? '• за 1' : ''} {item?.units?.find(unit => unit.id === item.price.unit).unit}</span>
                                        </span>
                                    </div>
                                    <div className='item-title-add-cart-container'>
                                        <div className="item-title-container">
                                            <span className="item-title">{item.title}</span>
                                            <span className="item-store">{item.store}</span>
                                        </div>

                                    </div>
                                </NavLink>
                                <button className='cart-add-button' onClick={() => showModal(item)}>
                                    <img src={cart} alt="" />
                                    В корзину
                                </button>
                            </div>
                        )
                    })
                }
                {
                    status === 'success' && items.length === 0 && <div className="description">Нет элементов</div>
                }
            </div>
            {
                status === 'error' &&
                <div className="error">Произошла ошибка при загрузки товаров, попробуйте перегрузить страницу</div>
            }
            <Modal modalContainerFocus={modalContainerFocus} modalClasses={modalClasses} hideModal={hideModal} style={{ width: '70%', height: 'auto', maxWidth: '900px' }} accept={addToCart} onKeyDown={(evt) => dispatch(setItem(handleInputChange(evt, itemState.units.find(unit => unit.id === itemState.price.unit), itemState)))}>
                <ModalCartItem addToCart={addToCart} itemState={itemState} setItem={setItem} submitText={'В корзину'} />
            </Modal>
        </div>
    )
}
export default Items